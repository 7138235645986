import React from 'react'
import DestinationSlider from './DestinationSlider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Image } from '../../Images'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import { CustomButton } from '../../CustomButton'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E0E5DE',
    justifyContent: 'center',
    padding: '6em 2em',
    width: '100%',
    display: 'flex',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 2em',
    },
  },
  into: {
    flexBasis: '90%',
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    textTransform: 'uppercase',
    wordWrap: 'normal',
    lineHeight: '1',
    letterSpacing: '0em',
    fontSize: theme.typography.pxToRem(55),
    fontWeight: 400,
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(0),
      fontSize: theme.typography.pxToRem(35),
    },
  },
  details: {
    lineHeight: '1.875',
    fontSize: '1rem',
    marginTop: theme.spacing(6),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(13),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      fontFamily: `'Gotham Book', serif`,
    },
  },
  zi1: {
    zIndex: 1,
    marginTop: '1em',
  },
  titleSection: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(18),
    textAlign: 'left',
    textTransform: 'uppercase',
    lineHeight: 1.6,
    textDecoration: 'none',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(18),
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.between('md', 'md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(0),
    },
  },
  detailsSection: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(11),
    letterSpacing: '0em',
    lineHeight: 0.95,
    fontWeight: 550,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(11),
      textAlign: 'center',
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(11),
      textAlign: 'center',
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(1),
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  containerImage: {
    zIndex: 0,
    paddingTop: '-2em',
    paddingBottom: '4em',
    paddingLeft: '2em',
    [theme.breakpoints.between('md', 'md')]: {
      paddingLeft: '5em',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      paddingLeft: 'unset',
    },
  },
  containerSection: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(1),
    textTransform: 'none',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
}))

export const Destination = () => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid container className={classes.into}>
        <Grid item xs={12} sm={12} md={4} lg={3} className={classes.zi1}>
          <Typography className={classes.title}>
            {intl.formatMessage({ id: 'dreamDestination.title' })}
          </Typography>
          <Typography className={classes.details}>
            {intl
              .formatMessage({ id: 'dreamDestination.detail' })
              .split('\n')
              .map((s, index) => (
                <div key={index}>{s}</div>
              ))}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={12} md={8} lg={9} className={classes.containerImage}>
            <Image src={'HP_DDR_Preview.png'} alt={'Preview ddr'} layout="FULL_WIDTH" />
          </Grid>
        </Hidden>
      </Grid>
      <Grid item xs={12} md={12} lg={12} className={classes.containerSection}>
        <DestinationSlider />
      </Grid>
    </Grid>
  )
}

export default Destination
