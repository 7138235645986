import React from 'react'
import Box from '@material-ui/core/Box'
import Carousel from 'react-multi-carousel'
import Grid from '@material-ui/core/Grid'
import theme from '../../../Theme/theme'
import { DESTINATIONS } from '../../../utils/constants'
import { SliderImgDestination } from '../../SliderImgDestination'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import { useMediaQuery } from '@material-ui/core'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2500 },
    items: 6,
    partialVisibilityGutter: 100,
  },
  desktop: {
    breakpoint: { max: 2500, min: 1480 },
    items: 5,
    partialVisibilityGutter: 100,
  },
  smallDesktop: {
    breakpoint: { max: 1480, min: 1080 },
    items: 4,
    partialVisibilityGutter: 100,
  },
  tablet: {
    breakpoint: { max: 1080, min: 780 },
    items: 3,
  },
  largeMobile: {
    breakpoint: { max: 780, min: 580 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 580, min: 0 },
    items: 1,
  },
}

const useStyles = makeStyles((theme) => ({
  img: {
    paddingRight: '2rem',
    height: '370px',
    [theme.breakpoints.between('md', 'md')]: {
      paddingRight: '1.5rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingRight: '1rem',
    },
  },
}))

export const DestinationSlider = () => {
  const classes = useStyles()
  const intl = useIntl()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid>
      <Carousel
        responsive={responsive}
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={5000}
        containerClass="container"
        focusOnSelect={false}
        infinite
        centerMode={matches}
        partialVisible={!matches}
        keyBoardControl
        minimumTouchDrag={10}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
      >
        {Object.keys(DESTINATIONS).map((eachDestination) => (
          <Box className={classes.img}>
            <SliderImgDestination
              src={DESTINATIONS[eachDestination].sliderImg}
              alt={'Image link in a carousel for ' + eachDestination}
              text={intl.formatMessage({ id: `dreamDestination.${DESTINATIONS[eachDestination].translation}.title` })}
              subtext={intl.formatMessage({ id: `dreamDestination.${DESTINATIONS[eachDestination].translation}.subtitle` })}
              to={`/${intl.locale}/${eachDestination}`}
            />
          </Box>
        ))}
      </Carousel>
    </Grid>
  )
}

export default DestinationSlider
